<template>
  <div>
    <div class="vx-row mt-3">
      <header class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10">
        <h4>{{ $t("tracks.tracks-list.title") }}</h4>
        <p>{{ $t("tracks.tracks-list.subtitle") }}</p>
      </header>
      <div class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10 text-right space-x-4">
        <vs-button
          class="xs:w-full sm:w-auto rounded-lg"
          :to="{ name: exportRouteName }" >
          {{ $t("tracks.tracks-list.export-tracks-csv") }}
        </vs-button>
      </div>
    </div>
    <div>
      <Datatable
        :config="datatableConfig"
        :elements="tracks"
        :stripe="true"
        :draggable="false"
        :sst="true"
        :pagination="pagination"
        @action="handleAction"
        @filter="handleFilter"
      />
    </div>
  </div>
</template>

<script>

import { DateTime } from 'luxon';
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import { download } from '@/modules/Shared/Helpers/fileHelper'
import { formatSeconds } from '@/modules/Shared/Helpers/dateHelper'
import loader from "@/modules/Shared/Mixins/loader.js";

export default {
  name: 'list-tracks-page',
  components: {
    Datatable
  },
  props: {
    getTracksFunction: {
      type: Function,
      required: true
    },
    datatableConfig: {
      type: Array,
      required: true
    },
    exportRouteName: {
      type: String,
      required: true
    }
  },
  mixins: [loader],
  data () {
    return {
      tracks: [],
      pagination: {},
    }
  },
  methods: {
    async getTracks(parameters) {
      const tracks = await this.getTracksFunction(this.$route.params.uuid, {...parameters, })
      this.pagination = {
        per_page: tracks.per_page,
        total: tracks.total
      }
      this.tracks = tracks.data.map(
        track => (
          {...track,
            'duration': track.duration ? formatSeconds(track.duration) : '',
            'distance': track.distance ? Math.round(track.distance) + ' m' : '',
            'max_speed': track.max_speed ? track.max_speed.toFixed(2) + ' Km/h' : '',
            'ascent': track.ascent ? track.ascent + ' m' : '',
            'descent': track.descent ? track.descent + ' m' : '',
            'actions': [ 'download' ],
            'date': DateTime.fromSeconds(track.date).toFormat('dd-LL-yyyy')
          }
        )
      )
    },
    handleAction(type, uuid) {
      const track = this.tracks.find(track => track.uuid === uuid)
      download(track.kml, track.uuid + '.kml')
    },
    async handleFilter(params) {
      await this.load(async () => {
        await this.getTracks(PaginationService.parseParameters(params))
      })
    }
  },
  async created() {
    await this.load(async () => {
      await this.getTracks({})
    })
  }
}
</script>
